/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Form } from "semantic-ui-react";
import useFormatText from "../../../../../hooks/useFormatText";
import { useAppSelector } from "../../../../../hooks/redux/hooks";
import { ButtonWpp, ITemplate } from "../../../../../slices/messages.slice";
import NoAvatar from "../../../../../shared/components/NoAvatar.component";
import Avatar from "../../../../../shared/components/Avatar.component";
import Button from "../../../../../shared/components/Buttons/Button.component";
import { ICampaign } from "../../../../../slices/campaigns.slice";
import { ICommenttemplate } from "../../../../../slices/commenttemplates.slice";
import useReplaceVarsText from "../../../../../hooks/useReplaceVarsText";
import { IFile } from "../../../../../shared/models/interfaces/file.interface";
import File from "../../../../../shared/components/Files/File.component";

const TemplatePreview = ({
  file,
  results,
  campaign,
  commenttemplate,
}: {
  file?: string;
  results?: ITemplate;
  campaign?: ICampaign;
  commenttemplate?: ICommenttemplate;
}) => {
  const URL = process.env.REACT_APP_API_BASE_URL;

  const { user } = useAppSelector((state) => state.auth);
  const { selectedChat } = useAppSelector((state) => state.chats);
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);
  const { selectedTemplateParameters } = useAppSelector(
    (state) => state.messages
  );
  const { replaceVarsContent } = useReplaceVarsText();

  const headerTxt: string | undefined | boolean =
    results?.components[0]?.text && results.components[0].type === "HEADER"
      ? results?.components[0]?.text
      : false;
  let bodyTxt: string | undefined | boolean =
    results?.components[0]?.text && results.components[0].type === "BODY"
      ? results?.components[0]?.text
      : false;
  let footerTxt: string | undefined | boolean =
    results?.components[1]?.text && results.components[1].type === "FOOTER"
      ? results?.components[1]?.text
      : false;
  let buttons: ButtonWpp[] | undefined | boolean = footerTxt
    ? results?.components[2]?.buttons &&
      results.components[2].type === "BUTTONS"
      ? results?.components[2]?.buttons
      : false
    : results?.components[1]?.buttons &&
      results.components[1].type === "BUTTONS"
    ? results?.components[1]?.buttons
    : false;
  const headerImage: string | undefined | boolean =
    results?.components[0]?.format && results.components[0].format === "IMAGE"
      ? typeof file !== "undefined"
        ? file
        : typeof results?.components[0]?.example?.header_handle !==
            "undefined" && results?.components[0]?.example?.header_handle[0]
      : false;
  const headerVideo: string | undefined | boolean =
    results?.components[0]?.format && results.components[0].format === "VIDEO"
      ? typeof file !== "undefined"
        ? file
        : typeof results?.components[0]?.example?.header_handle !==
            "undefined" && results?.components[0]?.example?.header_handle[0]
      : false;
  const headerDocument: string | undefined | boolean =
    results?.components[0]?.format &&
    results.components[0].format === "DOCUMENT"
      ? typeof file !== "undefined"
        ? file
        : typeof results?.components[0]?.example?.header_handle !==
            "undefined" && results?.components[0]?.example?.header_handle[0]
      : false;
  if (headerTxt || headerImage || headerVideo || headerDocument) {
    bodyTxt =
      results?.components[1]?.text && results.components[1].type === "BODY"
        ? results?.components[1]?.text
        : false;
    footerTxt =
      results?.components[2]?.text && results.components[2].type === "FOOTER"
        ? results?.components[2]?.text
        : false;
    buttons =
      results?.components[3]?.buttons &&
      results.components[3].type === "BUTTONS"
        ? results?.components[3]?.buttons
        : false;
  }

  let couponCode = "";
  selectedTemplateParameters?.forEach((_parameter) => {
    if (
      _parameter.parameters[0].type === "coupon_code" &&
      typeof _parameter.parameters[0].text !== "undefined"
    ) {
      couponCode = _parameter.parameters[0].text;
    }
  });

  const getAvatar = () => {
    if (typeof campaign?._id !== "undefined") {
      return (
        <NoAvatar
          displaytext={
            selectedLocalCampaign?.user?.name ||
            selectedSettings?.defaultagent?.name ||
            "--"
          }
          elementClass="p-0"
          labelClass="text-[9px]"
        />
      );
    }
    if (user?.avatar?.path && user?.avatar?.name) {
      return (
        <Avatar
          path={user.avatar?.path}
          name={user.avatar?.name}
          title="Foto do perfil"
        />
      );
    }
    return (
      <NoAvatar
        displaytext={user?.name || "--"}
        elementClass="p-0"
        labelClass="text-[9px]"
      />
    );
  };

  const getContent = () => {
    if (typeof commenttemplate?.content !== "undefined") {
      return (
        <div className="max-h-[275px] overflow-y-scroll">
          <span
            id="commenttemplate-content"
            className="px-[10px] pb-[5px] pt-0 inline-block whitespace-pre-line text-[12px]"
            dangerouslySetInnerHTML={{
              __html: useFormatText(
                typeof selectedChat?._id !== "undefined"
                  ? replaceVarsContent(commenttemplate?.content)
                  : commenttemplate?.content
              ),
            }}
          />
        </div>
      );
    }
    return (
      <>
        {/* HEADER */}
        {headerTxt ? (
          <div>
            <span
              id="headerTxt"
              className="preview_header px-[10px] pb-[5px] pt-0 inline-block whitespace-pre-line text-[12px] font-semibold"
              dangerouslySetInnerHTML={{
                __html: useFormatText(headerTxt),
              }}
            />
          </div>
        ) : null}
        {headerImage ? (
          <div className="flex w-[150px] mx-auto my-4">
            <img
              className="fit-content flex-justify-center"
              id="headerImage"
              src={headerImage}
              alt="Imagem do template"
            />
          </div>
        ) : null}
        {headerVideo ? (
          <div className="flex w-[300px] mx-auto my-4">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              controls
              className="fit-content flex-justify-center"
              id="headerVideo"
              src={headerVideo}
            />
          </div>
        ) : null}
        {headerDocument ? (
          <div className="flex w-full items-center justify-center mx-auto my-4">
            <i className="las la-file-download text-[40px]" />
            <a
              id="headerDocument"
              href={headerDocument}
              target="_blank"
              type="application/octet-stream"
              download="arquivo-template"
              rel="noreferrer"
            >
              Clique para fazer o download do arquivo
            </a>
          </div>
        ) : null}
        {/* BODY */}
        {bodyTxt ? (
          <span
            id="bodyTxt"
            className="preview_body px-[10px] pb-[5px] pt-0 inline-block whitespace-pre-line text-[12px]"
            dangerouslySetInnerHTML={{
              __html: useFormatText(bodyTxt as string),
            }}
          />
        ) : null}
        {/* FOOTER */}
        {footerTxt ? (
          <h2
            id="footerTxt"
            className="px-[10px] py-[5px] text-[11px] text-gray-777"
          >
            {footerTxt}
          </h2>
        ) : null}
        {/* BUTTONS */}
        {buttons ? (
          <div className="my-3 mx-2">
            {(buttons as ButtonWpp[]).map(
              (button: ButtonWpp, index: number) => {
                if (button.type !== "COPY_CODE") {
                  return (
                    <Button
                      extraClass="my-2"
                      minWidth
                      width="w-full"
                      inverted
                      label={button.text}
                      key={`button-${index.toString()}`}
                    />
                  );
                }
                return (
                  <div key={`button-${index.toString()}`} className="my-1">
                    <Form.Input
                      key="templateButtonCopy"
                      id="templateButtonCopy"
                      placeholder={button.text}
                      autoComplete="off"
                      fluid
                      value={
                        couponCode.length === 0 &&
                        typeof button.example !== "undefined"
                          ? button.example[0]
                          : couponCode
                      }
                      action={{
                        icon: "copy",
                        content: button.text,
                      }}
                    />
                  </div>
                );
              }
            )}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      {typeof commenttemplate?.content !== "undefined" ? null : (
        <label>Prévia</label>
      )}
      <div id="bubble" className="flex relative not-mine">
        <div
          onContextMenu={(e) => {
            e.stopPropagation();
          }}
          className="ballon-preview relative w-full"
        >
          <div className="flex">
            <div className="p-[5px] flex-1 flex relative justify-between">
              <div className="flex items-center">
                <div className="h-[25px] w-[25px] mr-[10px]">{getAvatar()}</div>
                <div className="relative flex flex-col">
                  <h2 className="font-semibold text-[12px] text-black">
                    {typeof campaign?._id !== "undefined"
                      ? selectedLocalCampaign?.user?.name ||
                        selectedSettings?.defaultagent?.name ||
                        "--"
                      : user?.name}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {getContent()}
          {typeof commenttemplate?.files !== "undefined" &&
          commenttemplate?.files.length > 0 ? (
            <div className="px-[10px] mt-4">
              <label className="text-[12px] mb-[2px]">
                Anexos ({commenttemplate.files.length})
              </label>
              <div className="max-h-[80px] overflow-y-scroll pr-2">
                {(commenttemplate.files as IFile[]).map((_file) =>
                  typeof _file?.size !== "undefined" ? (
                    <a
                      key={_file?._id}
                      href={`${URL}/api/files/${_file?.path}/${_file?.name}`}
                      target="_blank"
                      title={_file?.name}
                      rel="noopener noreferrer"
                    >
                      <File
                        message
                        file={_file as IFile}
                        href={`${URL}/api/files/${_file?.path}/${_file?.name}`}
                      />
                    </a>
                  ) : null
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

TemplatePreview.defaultProps = {
  file: undefined,
  results: undefined,
  campaign: undefined,
  commenttemplate: undefined,
};

export default TemplatePreview;
