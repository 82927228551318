import React from "react";

const Button = ({
  red,
  green,
  orange,
  disabled,
  extraClass,
  label,
  onClick,
  icon,
  widget,
  inverted,
  color,
  hoverColor,
  minWidth,
  height,
  width,
  title,
}: {
  red?: boolean;
  green?: boolean;
  orange?: boolean;
  disabled?: boolean;
  extraClass?: string;
  label?: string;
  onClick?: () => void;
  icon?: string;
  widget?: boolean;
  inverted?: boolean;
  color?: string;
  hoverColor?: boolean;
  minWidth: boolean;
  height?: string;
  width?: string;
  title?: string;
}) => {
  const getHoverbg = () => {
    if (color) return `hover:bg-[${color}C0]`;
    if (red) return "hover:bg-red-alphaC0";
    if (orange) return "hover:bg-agidesk-alphaC0";
    if (green) return "hover:bg-green-alphaC0";
    return "hover:bg-agitalks-alphaC0";
  };

  const getBg = () => {
    if (color) return `bg-[${color}]`;
    if (red) return "bg-red";
    if (orange) return "bg-agidesk";
    if (green) return "bg-green";
    return "bg-agitalks";
  };

  const getInverted = () => {
    if (color) return `text-[${color}] border-[${color}]`;
    if (orange) return "text-agidesk border-agidesk";
    if (red) return "text-red border-red";
    if (green) return "text-green border-green";
    return "text-agitalks border-agitalks";
  };

  const _classHeight = `${typeof height !== "undefined" ? height : "h-[36px]"}`;
  const _classWidth = `${typeof width !== "undefined" ? width : ""}`;
  const _classMinWidth = `${minWidth ? "min-w-[125px]" : ""}`;
  const _classWidget = `${!widget ? "" : "mr-2"}`;
  const _classDefault = `py-[4px] px-4 cursor-pointer items-center inline-flex justify-center rounded-3xl border shadow-sm ${getHoverbg()}`;
  const _classDisable =
    "disabled:cursor-default disabled:text-black-alpha55 disabled:bg-disable disabled:border-disable";
  const _classType = !inverted
    ? `text-white ${getBg()}`
    : `bg-white hover:text-white ${getInverted()} ${
        hoverColor ? `hover:border-footer` : "hover:border-white"
      }`;

  const className = `${_classWidth} ${_classHeight} ${_classMinWidth} ${extraClass} ${_classWidget} ${_classDefault} ${_classDisable} ${_classType}`;

  return (
    <button
      type="button"
      title={title || label}
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      {icon ? (
        <i className={`${icon} ${!minWidth ? "text-md" : "text-lg"}`} />
      ) : null}
      {label ? (
        <p
          className={`${
            !minWidth && !width
              ? "hidden md:flex md:items-center md:ml-2"
              : "ml-2"
          } text-[12px] font-semibold`}
        >
          {label}
        </p>
      ) : null}
    </button>
  );
};

Button.defaultProps = {
  icon: "",
  widget: false,
  disabled: false,
  inverted: false,
  extraClass: "",
  onClick: undefined,
  label: "",
  color: "",
  red: false,
  orange: false,
  green: false,
  hoverColor: false,
  height: "h-[36px]",
  width: "",
  title: undefined,
};

export default Button;
